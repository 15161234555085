<template>
  <div class="animated reveal">
    <div class="text-2xl font-bold text-invest-primary">Investments</div>
    <div
      class="
        grid
        w-full
        grid-cols-1
        gap-8
        mt-5
        sm:grid-cols-2
        md:grid-cols-3 md:w-10/12
      "
    >
      <investment @click="create">
        <div class="flex items-center justify-center">
          <div
            class="
              flex
              items-center
              justify-center
              w-8
              h-8
              mt-5
              rounded-full
              bg-invest-purple
            "
          >
            <ion-icon name="add-outline" class="text-lg text-white" />
          </div>
        </div>
        <p class="mt-3 text-xs text-center text-invest-dark">
          Create New Investment
        </p>
      </investment>
      <template v-if="loading">
        <skeleton-loader
          width="250px"
          height="150px"
          class-name="rounded-lg"
          v-for="i in 5"
          :key="i"
        />
      </template>
      <template v-else>
        <investment
          v-for="(investment, index) in investments"
          :key="`investment-${index}`"
          :investment="investment"
          @click="investmentDetails(investment.id)"
        />
      </template>
    </div>
  </div>

  <create-investment ref="createInvestmentModal" :key="componentKey" />
</template>
<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  onBeforeMount,
  ref,
  nextTick,
} from 'vue';
import investmentModule from '@/modules/investment/investmentModule';

export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const { investments, getAllInvestment, loading, investmentDetails } =
      investmentModule();

    const componentKey = ref<number>(0);
    const createInvestmentModal = ref<any>(null);

    onBeforeMount(getAllInvestment);

    const create = () => {
      componentKey.value++;
      nextTick(() => createInvestmentModal.value.open());
    };

    return {
      investments,
      investmentDetails,
      loading,
      createInvestmentModal,
      create,
      componentKey,
    };
  },
});
</script>
