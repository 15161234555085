
import {
  defineComponent,
  defineAsyncComponent,
  onBeforeMount,
  ref,
  nextTick,
} from 'vue';
import investmentModule from '@/modules/investment/investmentModule';

export default defineComponent({
  components: {
    CreateInvestment: defineAsyncComponent(
      () => import('@/components/investment/create-investment/Index.vue'),
    ),
  },
  setup: () => {
    const { investments, getAllInvestment, loading, investmentDetails } =
      investmentModule();

    const componentKey = ref<number>(0);
    const createInvestmentModal = ref<any>(null);

    onBeforeMount(getAllInvestment);

    const create = () => {
      componentKey.value++;
      nextTick(() => createInvestmentModal.value.open());
    };

    return {
      investments,
      investmentDetails,
      loading,
      createInvestmentModal,
      create,
      componentKey,
    };
  },
});
